import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { calculateOffset, userHasRoles } from '@asd-stan/helpers/app-utils';
import {
	getStandardPublicService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { getUserService } from '@asd-stan/user/infrastructure/getters';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { Toggle } from '@components/toggle/toggle';
import { Field, FormikValues, useFormikContext } from 'formik';

import { ProjectLeader } from '../../general/project-leader/project-leader';
import { NewOriginatorForm } from '../../originator/new-originator-form/new-originator-form';
import { RadioForm } from '../../originator/radio-form/radio-form';

interface Props {
	isSaveClicked: boolean;
}

const LIMIT = 6;

export const Originator: React.FC<Props> = observer(({ isSaveClicked }) => {
	const params = useParams();
	const { t } = useTranslation();
	const { values, setFieldValue, handleSubmit } = useFormikContext<FormikValues>();

	const [isAsdOriginator, setIsAsdOriginator] = useState<boolean>(values.isAsdOriginator);
	const [existOriginator, setExistOriginator] = useState<boolean>(values.radioForm);
	const [loadingExistOriginatorsOptions, setLoadingExistOriginatorsOptions] =
		useState<boolean>(false);

	const userService = getUserService();
	const standardService = getStandardService();
	const currentUserService = getCurrentUserService();
	const standardPublicService = getStandardPublicService();

	const isEsOrDirector = userHasRoles(
		[SystemRole.ES, SystemRole.DIRECTOR],
		currentUserService.userRoles!
	);
	console.log(values.originator);
	const originatorOptions = userService.originators.map(originator => {
		return {
			label: `${originator.firstName ?? ''} ${originator.lastName ?? ''}`,
			value: {
				userId: originator.id,
				firstName: originator.firstName,
				lastName: originator.lastName,
				email: originator.email,
				phone: originator.phone,
				companyId: originator.company && originator.company.id,
				countryId: originator.country && originator.country.id,
				positions: originator.positions,
			},
			roles: originator.systemRoles.map((role, index) => <span key={index}>{role}</span>),
		};
	});

	const handleLoadOriginators = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const res = await userService.getUserList(
			{
				systemRoles: ['Expert', 'MC', 'TAC', 'Board member'],
				search: !!search ? search : undefined,
			},
			LIMIT,
			calculateOffset(page, LIMIT)
		);
		const options = res.data.map(user => {
			return {
				label: <ProjectLeader user={user} />,
				value: {
					userId: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					companyId: user.company && user.company.id,
					countryId: user.country && user.country.id,
					positions: user.positions,
				},
			};
		});
		const hasMore = page < Math.ceil(res.totalNumber / LIMIT);
		return {
			options,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const existOriginatorsOptions = standardService.originatorList.map(originator => {
		return {
			value: {
				id: originator.id,
				firstName: originator.firstName,
				lastName: originator.lastName,
				phone: originator.phone,
				email: originator.email,
				companyId: originator.company && originator.company.id,
				countryId: originator.country && originator.country.id,
				positions: originator.positions && originator.positions.map(position => position.id),
			},
			label: (
				<>
					<b style={{ fontWeight: 500 }}>{`${originator.firstName ?? ''} ${
						originator.lastName ?? ''
					}`}</b>
					{`${originator.email === null ? '' : `, ${originator.email}, `}${
						originator.phone === null ? '' : `${originator.phone},`
					} ${originator.company?.name ?? ''}${
						originator.country === null ? '' : `, ${originator.country?.name}`
					}${
						originator.positions && originator.positions.length > 0
							? ', ' + originator.positions.map(position => ` ${position.name}`)
							: ''
					}`}
				</>
			),
			customLabel: `${originator.firstName} ${originator.lastName}`,
			customOption: (
				<div
					style={
						originator.email ||
						originator.phone ||
						originator.company ||
						originator.country ||
						(originator.positions && originator.positions.length > 0)
							? { paddingTop: '10px' }
							: undefined
					}>
					<p>{`${originator.email ?? ''}${
						originator.phone === null ? '' : `, ${originator.phone}`
					}`}</p>
					<p>
						{`${originator.company?.name ?? ''}${
							originator.country === null ? '' : `, ${originator.country?.name}`
						}${
							originator.positions && originator.positions.length > 0
								? ', ' + originator.positions.map(position => ` ${position.name}`)
								: ''
						}`}
					</p>
				</div>
			),
		};
	});

	const loadOriginators = async (isAsdOriginator: boolean) => {
		setFieldValue('originator', { label: '', value: '' });
		if (isAsdOriginator) {
			await userService.getUserList({
				systemRoles: ['Expert', 'MC', 'TAC', 'Board member'],
			});
		} else {
			setLoadingExistOriginatorsOptions(true);
			await standardService.getOriginatorList();
			setLoadingExistOriginatorsOptions(false);
		}
	};

	const handleAsdOriginator = async () => {
		setExistOriginator(true);
		setFieldValue('radioForm', true);
		loadOriginators(isAsdOriginator);
		setFieldValue('isAsdOriginator', !isAsdOriginator);
		setIsAsdOriginator(!isAsdOriginator);
		setFieldValue('newOriginator', {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			company: '',
			companyCountry: '',
		});
		handleSubmit();
	};

	const handleExistOriginator = (existOriginator: boolean) => {
		setExistOriginator(existOriginator);
		setFieldValue('radioForm', existOriginator);
		setFieldValue('originator', { label: '', value: '' });
		setFieldValue('newOriginator', {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			company: '',
			companyCountry: '',
		});
		if (existOriginator) {
			loadOriginators(false);
		}
		standardPublicService.clearOriginator();
	};

	useEffect(() => {
		if (!userService) {
			return;
		}

		const onLoadPage = async () => {
			if (!isEsOrDirector && currentUserService.currentUser && !params.id) {
				return setFieldValue('originator', {
					value: {
						userId: currentUserService.currentUser.id,
						firstName: currentUserService.currentUser.firstName,
						lastName: currentUserService.currentUser.lastName,
						phone: currentUserService.currentUser.phone,
						email: currentUserService.currentUser.email,
						companyId: currentUserService.currentUser.company?.id,
						countryId: currentUserService.currentUser.country?.id,
					},
					label: t('standard.createNWP.originator.currentUserIsOriginator', {
						name: currentUserService.currentUser?.firstName,
						surname: currentUserService.currentUser?.lastName,
					}),
				});
			}
			if (originatorOptions.length === 0) {
				await userService.getUserList({
					systemRoles: ['Expert', 'MC', 'TAC', 'Board member'],
				});
			}
			if (existOriginatorsOptions.length === 0 && isAsdOriginator) {
				setLoadingExistOriginatorsOptions(true);
				await standardService.getOriginatorList();
				setLoadingExistOriginatorsOptions(false);
			}
		};

		onLoadPage();
	}, [
		existOriginatorsOptions.length,
		isAsdOriginator,
		isEsOrDirector,
		originatorOptions.length,
		params.id,
		setFieldValue,
		standardService,
		t,
		userService,
	]);

	useEffect(() => {
		setIsAsdOriginator(values.isAsdOriginator);
	}, [values.isAsdOriginator]);

	const handleChangeRadio = (value: boolean) => {
		handleExistOriginator(value);
		handleSubmit();
	};

	return (
		<>
			{isEsOrDirector && (
				<Field
					component={Toggle}
					name="isAsdOriginator"
					checked={isAsdOriginator}
					onChange={handleAsdOriginator}
					label={t('standard.createNWP.originator.toggleTitle')}
					topMargin
				/>
			)}
			{!isAsdOriginator && (
				<Field
					component={FormSelectAsyncPagination}
					name="originator"
					title={t('standard.createNWP.originator.title')}
					defaultOptions
					loadOptions={handleLoadOriginators}
					disabled={!isEsOrDirector}
					showError
					fullWidth
					useSubmitOnChange
				/>
			)}
			{isAsdOriginator && (
				<>
					<Field
						name="radioForm"
						component={RadioForm}
						checked={existOriginator}
						onChange={() => handleChangeRadio(true)}
						label={t('standard.createNWP.originator.existOriginator')}>
						{existOriginator && (
							<Field
								component={FormSelectAsyncPagination}
								name="originator"
								title={t('standard.createNWP.originator.title')}
								loadOptions={handleLoadOriginators}
								mandatory={!standardService.singleStandard?.isMigrated}
								showError
								isLoading={loadingExistOriginatorsOptions}
								fullWidth
								useSubmitOnChange
							/>
						)}
					</Field>

					<RadioForm
						checked={!existOriginator}
						onChange={() => handleChangeRadio(false)}
						label={t('standard.createNWP.originator.newOriginator')}>
						{!existOriginator && (
							<NewOriginatorForm
								isSaveClicked={isSaveClicked}
								existOriginator={existOriginator}
								setExistOriginator={setExistOriginator}
							/>
						)}
					</RadioForm>
				</>
			)}
		</>
	);
});
